import {MODES, ACTIONS} from './Constants'

const R = require('ramda')

const reduce = (_state, action='NOOP', payload=null) => {

  let state = R.clone(_state)

  switch (action) {

    case ACTIONS.SET_PLAYER_MODE:
      state.mode = MODES.PLAYER
      state.invalidCode = false
      state.ReactGA.event("screen_view", {
        app_name: "class-clickers",
        screen_name: "set-player",
        player_key: state.pKey
      });
      // state.ReactGA.pageview('set-player')
      break;

    case ACTIONS.SET_CONTROL_MODE:
      state.mode = MODES.CONTROL
      state.ReactGA.event("screen_view", {
        app_name: "class-clickers",
        screen_name: "set-control",
        player_key: state.pKey
      });
      // state.ReactGA.pageview('set-control')
      break;

    case ACTIONS.SET_FB_STATE:
      if (state.pKey && payload.fbData && (!payload.fbData.playerList || (!payload.fbData.playerList[state.pKey]))) {
        state.mode = MODES.REMOVED
      } else {
        state.fbData = payload.fbData
      }
      break

    case ACTIONS.SET_PLAYER_INFO:
      state.name = payload.name
      state.pKey = payload.pKey
      break

    case ACTIONS.INVALID_CODE:
      state.invalidCode = true
      break;

    default:
      break;
  }

  return state
}

export default reduce
