import firebase from 'firebase'
import {FB_CONFIG, ACTIONS, STATES, SYSTEM} from './Constants'
import moment from 'moment'

const R = require('ramda')
const Fb = (dispatch, getState) => {

  // Initialize firebase
  firebase.initializeApp(FB_CONFIG)
  const _db =  firebase.database()

  // Find a name in the player list
  const findNameInList = (name, inList) => {
    return R.find(player => player.name === name, R.map(arr => arr[1], R.toPairs(inList)))
  }

  const prune = () => {
    _db.ref().once('value', snap => {
      let listToRemove = []
      let data = snap.val()
      let now = moment().unix()
      R.mapObjIndexed((item, key) => {
        if (now - (item.time / 1000) > SYSTEM.SECONDS_PER_DAY) {
          listToRemove.push(key)
        }
      }, data)
      R.forEach(item => _db.ref(item).remove(), listToRemove)
    })
  }

  prune();

  return {

    // Set the new app state
    // id:        (int) Session ID
    // newState:  (int) New app state
    setAppState: (id, newState) => {
      _db.ref(id).update({
        appState: newState,
        time: firebase.database.ServerValue.TIMESTAMP
      })
    },

    // Set controller
    // id:          (int) Session ID
    // onFbChange:  (fcn) Callback to fired when Firebase data changes
    setControl: (id, onFbChange) => {
      _db.ref(id).once('value', snap => {
        if (snap.val()) {
          _db.ref(id).on('value', onFbChange)
        } else {
          // Set a new controller
          let fbData = {
            id,
            appState: STATES.ARMED,
            enable: false,
            time: firebase.database.ServerValue.TIMESTAMP
          }
          _db.ref(id).set(fbData)
            .then(() => {
              _db.ref(id).on('value', onFbChange)
            })
        }
      })
    },

    // Set player
    // id:          (int) Session ID
    // name:        (str) Player name
    // onFbChange:  (fcn) Callback to fire when Firebase data changes
    setPlayer: (id, name, onFbChange) => {
      name = name.toUpperCase()
      // _db.ref(id+'/playerList).once('value')
      _db.ref(id).once('value')
        .then(snap => {
          let rec = snap.val()
          if (rec) {
            let list = rec.playerList
            let player = findNameInList(name, list)
            if (!player) {
              let pKey = _db.ref(id + '/playerList').push().key
              _db.ref(id + '/playerList/' + pKey).set({pKey, name, score: 0})
              dispatch(ACTIONS.SET_PLAYER_INFO, {pKey, name})
            } else {
              dispatch(ACTIONS.SET_PLAYER_INFO, {pKey: player.pKey, name: player.name})
            }
            _db.ref(id).on('value', onFbChange)
          } else {
            dispatch(ACTIONS.INVALID_CODE);
          }
        })
    },

    // Player has buzzed in
    // id:        (int) Session ID
    // pKey:      (str) Key of the player record
    setBuzzedIn: (id, pKey) => {
      _db.ref(id+'/buzzedInPlayer').push({
        time: firebase.database.ServerValue.TIMESTAMP,
        pKey
      })
        .then(() => {
          _db.ref(id+'/appState').set(STATES.LOCKOUT)
        })
    },

    // Change the player's score
    // id:        (int) Session ID
    // player:    (obj) Player object
    // delta:     (int) Amount to adjust the score
    changeScore: (id, player, delta) => {
      _db.ref(id+'/playerList/'+player.pKey+'/score').set(player.score + delta)
    },

    // Clear all players
    // id:        (int) Session ID
    clearAll: (id) => {
      _db.ref(id+'/playerList').remove()
      _db.ref(id+'/buzzedInPlayer').remove()
    },

    // Reset the session to wait for a new buzzer
    // id:        (int) Session ID
    reset: (id) => {
      _db.ref(id).update({
        appState: STATES.ARMED,
        buzzedInPlayer: null,
        time: firebase.database.ServerValue.TIMESTAMP
      })
    },

    // Set the new player list
    // id:            (int) Session ID
    // newPlayerList: (obj) new player list
    newPlayerList: (id, newPlayerList) => {
      _db.ref(id+'/playerList/').set(newPlayerList)
    },

    toggleEnable: (id, state) => {
      _db.ref(id+'/enable').set(!state)
    }
  }
}

export default Fb