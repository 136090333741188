import React, {Component} from 'react';
import {CardFooter} from 'reactstrap';

const p = require('../../package.json')

class Footer extends Component {

  render() {
    return (
      <CardFooter key="footer" className="footer">
        <span>Clickers V{p.version}</span><br/>
        <span>&copy; Roger Jaffe 2018</span>
      </CardFooter>
    );
  }
}

export default Footer;
