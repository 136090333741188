// Initialize Firebase
var FB_CONFIG = {
  apiKey: "AIzaSyCImFz47URSjD2i-GYZ3ZVXYXMpFLvfax8",
  authDomain: "class-clickers.firebaseapp.com",
  databaseURL: "https://class-clickers.firebaseio.com",
  projectId: "class-clickers",
  storageBucket: "class-clickers.appspot.com",
  messagingSenderId: "287901997616"
};

var SYSTEM = {
  ID_LENGTH: 4,
  SECONDS_PER_DAY: 60*60*24
}

const STATES = {
  SET_ID:       0,
  ARMED:        1,
  LOCKOUT:      2
}

const MODES = {
  CHOOSE:       0,
  CONTROL:      1,
  PLAYER:       2,
  REMOVED:      3
}

const ACTIONS = {
  SET_PLAYER_MODE:    'SET_PLAYER_MODE',
  SET_CONTROL_MODE:   'SET_CONTROL_MODE',
  SET_FB_STATE:       'SET_FB_STATE',
  SET_PLAYER_INFO:    'SET_PLAYER_INFO',
  INVALID_CODE:       'INVALID_CODE'
}

export {FB_CONFIG, SYSTEM, STATES, MODES, ACTIONS}
