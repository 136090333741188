import React, {Component} from 'react';
import Footer from './Footer'
import {Card, CardHeader, CardBody, Row, Col, Button} from 'reactstrap';

// Chooses Control or Player apps
class ChooseMode extends Component {

  render() {
    return (
      <Row>
        <Col sm={{size: 6, offset: 3}}>
          <Card>
            <CardHeader>
              <h4>Academic League Clickers</h4>
            </CardHeader>
            <CardBody>
              <Row className="choose-mode">
                <Col md={{size: 4, offset: 2}}>
                  <Button color="primary" block onClick={() => {
                    this.props.dispatch('SET_PLAYER_MODE')
                  }}>Player</Button>
                </Col>
                <Col md={{size: 4}}>
                  <Button color="primary" block onClick={() => {
                    this.props.dispatch('SET_CONTROL_MODE')
                  }}>Controller</Button>
                </Col>
              </Row>
            </CardBody>
            <Footer/>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ChooseMode;
