import React, {Component} from 'react';
import Footer from '../shared/Footer'
import moment from 'moment'
import {Card, CardHeader, CardBody, Row, Col, Form, FormGroup, Label, Input, Button} from 'reactstrap';
import {SYSTEM} from '../shared/Constants'

let R = require('ramda')

// Obtains the login code for the Control app
class SetControl extends Component {

  constructor(props) {
    super(props)
    let lastIdObj = (localStorage) ? JSON.parse(localStorage.getItem('clickers-last-id')) : null
    if (!lastIdObj || (moment().unix() - lastIdObj.time > 300)) lastIdObj = null
    this.state = {
      id: this.getRandomId(SYSTEM.ID_LENGTH),
      ...lastIdObj
    }
    this.loginClick = this.loginClick.bind(this)
    this.useLastId = this.useLastId.bind(this)
    this.onIdChange = this.onIdChange.bind(this)
  }

  getRandomId(length) {
    let charset = '0123456789'
    return R.reduce((str, idx) => str + charset[parseInt(Math.random() * charset.length, 10)], '', R.range(0, length))
  }

  loginClick() {
    this.props.fb.setControl(this.state.id, this.props.onFbChange)
    if (localStorage) localStorage.setItem('clickers-last-id', JSON.stringify({
      lastId: this.state.id,
      time: moment().unix()
    }))
  }

  useLastId() {
    this.props.fb.setControl(this.state.lastId, this.props.onFbChange)
  }

  onIdChange(evt) {
    this.setState({id: evt.target.value})
  }

  render() {
    return (
      <Row>
        <Col sm={{size: 6, offset: 3}}>
          <Card>
            <CardHeader>
              <h4>Controller login</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}}>
                  <Form>
                    <FormGroup>
                      <Label for="setId">Set controller ID</Label>
                      <Input id="setId"
                             type="text"
                             onChange={this.onIdChange}
                             value={this.state.id}
                             placeholder="Controller ID" />
                    </FormGroup>
                    <FormGroup>
                      <Button color="primary"
                              block
                              disabled={this.state.id.length !== SYSTEM.ID_LENGTH}
                              onClick={this.loginClick}>Login</Button>
                    </FormGroup>
                    {
                      (this.state.lastId) ?
                        <div>
                          <Button block
                                  color="primary"
                                  onClick={this.useLastId}>Use previous ID: {this.state.lastId}</Button>
                        </div> : null
                    }
                  </Form>
                </Col>
              </Row>
            </CardBody>
            <Footer />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default SetControl;
