import React, { Component } from 'react';
import {STATES, MODES, ACTIONS} from './shared/Constants'
import reduce from './shared/reduce'
import Fb from './shared/Fb'
import ReactGA from 'react-ga4'

import SetControl from './control/SetControl'
import ChooseMode from './shared/ChooseMode'
import Standby from './control/Standby'
import SetPlayer from './player/SetPlayer'
import Armed from './player/Armed'
import PlayerRemoved from './player/PlayerRemoved'

const R = require('ramda');

// Main App component
class App extends Component {

  constructor(props) {
    super(props)
    this.dispatch = this.dispatch.bind(this)
    this.getState = this.getState.bind(this)
    this.onFbChange = this.onFbChange.bind(this)
    this.whoIsFirst = this.whoIsFirst.bind(this)
    this.setPoints = this.setPoints.bind(this)
    this.fb = new Fb(this.dispatch, this.getState)

    ReactGA.initialize('G-8RJR02G0EQ')

    this.state = {
      // State variables
      correctPoints: 10,
      fbData: null,
      mode: MODES.CHOOSE,
      wrongPoints: 5,
      invalidCode: false,

      // Functions
      fb: this.fb,
      dispatch: this.dispatch,
      onFbChange: this.onFbChange,
      setPoints: this.setPoints,
      whoIsFirst: this.whoIsFirst,

      // Analytics
      ReactGA: ReactGA
    }
  }

  getState() {
    return this.state
  }

  // Callback fired when FB data has changed
  onFbChange(snap) {
    this.state.dispatch(ACTIONS.SET_FB_STATE, {fbData: snap.val()})
  }

  // App store
  dispatch(action, payload) {
    this.setState(reduce(this.state, action, payload))
  }

  // Scan the playerBuzzedIn object to see who buzzed in first
  whoIsFirst(props) {
    if (props.fbData.buzzedInPlayer) {
      // If there are buzzed in records...
      // Retrieve the player object
      let keys = R.map(rec => rec[1], R.toPairs(props.fbData.buzzedInPlayer))
      // and sort the player objects by the time it was sent to the server
      keys = R.sort((a,b) => a.time-b.time, keys)
      // Return the first player in
      return props.fbData.playerList[keys[0].pKey]
    } else {
      return null
    }
  }

  // Sets new point values for the correct and wrong answers
  setPoints(evt) {
    this.setState({[evt.currentTarget.getAttribute('id')]: parseInt(evt.currentTarget.value,10)})
  }

  render() {
    let page = null
    switch (this.state.mode) {

      // Choose which application we want
      case MODES.CHOOSE:
        page = <ChooseMode {...this.props}
                           {...this.state}
                           {...this.fcns} />;
        break;

      // Controller application requested
      case MODES.CONTROL:
        if (this.state.fbData === null) {
          // Need to select the ID first
          page = <SetControl {...this.props}
                             {...this.state}/>;
        } else if (this.state.fbData.appState >= STATES.ARMED) {
          // Control logged in, wait for players to buzz in
          page = <Standby {...this.props}
                          {...this.state}/>
        }
        break;

      // Player application requested
      case MODES.PLAYER:
        if (this.state.fbData === null) {
          // Need for the player to provide the login code and name
          page = <SetPlayer {...this.props}
                            {...this.state}/>;
        } else if (this.state.fbData.appState >= STATES.ARMED) {
          // Wait for player to buzz in response to a question
          page = <Armed {...this.props}
                        {...this.state}/>;
        }
        break;

      case MODES.REMOVED:
        page = <PlayerRemoved {...this.props}
                              {...this.state}/>;
        break;

      default:
        break;

    }

    return (
      <div>
        {page}
      </div>
    );
  }
}

export default App;
