import React, {Component} from 'react';
import Footer from '../shared/Footer'
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';

class PlayerRemoved extends Component {

  constructor(props) {
    super(props)
    this.loginAgain = this.loginAgain.bind(this)
  }

  loginAgain() {
    window.location.reload()
  }

  render() {
    return (
      <Row>
        <Col sm={{size: 6, offset: 3}}>
          <Card>
            <CardHeader>
              <h4>Player login</h4>
            </CardHeader>
            <CardBody>
              <h4>You have been removed from the game</h4>
              <a onClick={this.loginAgain}>Log in again</a>
            </CardBody>
            <Footer />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default PlayerRemoved;
