import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import Footer from '../shared/Footer'
import {STATES} from '../shared/Constants'

class Armed extends Component {

  constructor(props) {
    super(props)
    this.buzzerClicked = this.buzzerClicked.bind(this)
  }

  buzzerClicked() {
    this.props.fb.setBuzzedIn(this.props.fbData.id, this.props.pKey)
  }

  render() {
    // Get who's first to buzz in if available
    let whoIsFirst = this.props.whoIsFirst(this.props)

    // Create the buzzer button element
    let buzzerElement
    if (this.props.fbData.appState === STATES.ARMED) {
      if (whoIsFirst && (whoIsFirst.pKey === this.props.pKey)) {
        buzzerElement = <Button block
                                size="lg"
                                className="player-button"
                                color="warning">Buzzed!</Button>
      } else {
        buzzerElement = <Button block
                                size="lg"
                                className="player-button"
                                color={(this.props.fbData.enable) ? "success" : "danger"}
                                disabled={!this.props.fbData.enable}
                                onClick={this.buzzerClicked}>{(this.props.fbData.enable) ? 'Buzz in!' : 'WAIT!'}</Button>
      }
    } else if (this.props.fbData.appState === STATES.LOCKOUT) {
      if (whoIsFirst && (whoIsFirst.pKey === this.props.pKey)) {
        buzzerElement = <Button block
                                className="player-button"
                                bize="lg"
                                color="warning">Buzzed in!</Button>
      } else {
        buzzerElement = <Button block
                                className="player-button"
                                bize="lg"
                                color="danger">Too late!</Button>
      }
    }

    return (
      <Row>
        <Col sm={{size: 10, offset: 1}} md={{size: 8, offset: 2}}>
          <Card>
            <CardHeader>
              <h4>{this.props.name}</h4>
            </CardHeader>
            <CardBody>
              <Col sm={{size: 8, offset: 2}} md={{size: 8, offset: 2}}>
                {buzzerElement}
              </Col>
            </CardBody>
            <Footer />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Armed;
