import React, {Component} from 'react';
import Sound from 'react-sound';
import {
  Card, CardHeader, CardBody, Row, Col, Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Label, Input, FormGroup, Form
} from 'reactstrap';
import Footer from '../shared/Footer'
import {STATES} from '../shared/Constants'
import buzzerSound from '../sounds/BuzzerSound.mp3'

const R = require('ramda');

// Control standby page.  Reflects the current state of the game
class Standby extends Component {

  constructor(props) {
    super(props)
    this.up = this.up.bind(this)
    this.down = this.down.bind(this)
    this.remove = this.remove.bind(this)
    this.correct = this.correct.bind(this)
    this.wrong = this.wrong.bind(this)
    this.next = this.next.bind(this)
    this.clearAll = this.clearAll.bind(this)
    this.getGrid = this.getGrid.bind(this)
    this.openSetup = this.openSetup.bind(this)
    this.openClearAll = this.openClearAll.bind(this)
    this.toggleEnable = this.toggleEnable.bind(this)
    this.whenFinishedPlaying = this.whenFinishedPlaying.bind(this)
    this.state = {
      firstPlayer: this.props.whoIsFirst(props),
      playSound: false,
      setupModal: false,
      clearAllModal: false,
    }
  }

  up(evt) {
    let pKey = evt.currentTarget.getAttribute('data-key')
    this.props.fb.changeScore(this.props.fbData.id, this.props.fbData.playerList[pKey], 1)
  }

  down(evt) {
    let pKey = evt.currentTarget.getAttribute('data-key')
    this.props.fb.changeScore(this.props.fbData.id, this.props.fbData.playerList[pKey], -1)
  }

  remove(evt) {
    let newPlayerList = R.dissoc(evt.currentTarget.getAttribute('data-key'), this.props.fbData.playerList)
    this.props.fb.newPlayerList(this.props.fbData.id, newPlayerList)
  }

  clearAll() {
    this.props.fb.clearAll(this.props.fbData.id)
    this.setState({clearAllModal: false})
  }

  whenFinishedPlaying() {
    this.setState({playSound: false})
  }

  componentWillReceiveProps(newProps) {
    let playSound = ((this.props.fbData.appState === STATES.ARMED) && (newProps.fbData.appState === STATES.LOCKOUT))
    this.setState({
      firstPlayer: this.props.whoIsFirst(newProps),
      playSound: this.state.playSound || playSound
    })
  }

  correct() {
    this.props.fb.changeScore(this.props.fbData.id, this.state.firstPlayer, this.props.correctPoints)
    this.next()
  }

  // Deduct the point value - note negative sign in the function call!
  wrong() {
    this.props.fb.changeScore(this.props.fbData.id, this.state.firstPlayer, -this.props.wrongPoints)
    this.next()
  }

  // Arm the app again for the next question
  next() {
    this.props.fb.reset(this.props.fbData.id)
  }

  // Open the setup modal to see the number of points for a correct and incorrect answer
  openSetup() {
    this.setState({setupModal: !this.state.setupModal})
  }

  openClearAll() {
    this.setState({clearAllModal: !this.state.clearAllModal})
  }

  toggleEnable() {
    this.props.fb.toggleEnable(this.props.fbData.id, this.props.fbData.enable)
  }

  // Create the grid of all the players
  getGrid(players) {
    // Alpha by name
    players = R.sort((a, b) => (a.name < b.name) ? -1 : 1, players)
    // Generate JSX
    return (players.length > 0) ?
      <Row>
        {
          players.map(player => {
            return (
              <Col md={3} key={player.pKey}>
                <Row className="player-cell">
                  <Col md={2} className="player-cell-controls">
                    <a onClick={this.up} data-key={player.pKey}>Up</a>
                    <a onClick={this.down} data-key={player.pKey}>Down</a>
                    <a onClick={this.remove} data-key={player.pKey}>Remove</a>
                  </Col>
                  <Col md={7} className="board-player-center">{player.name}</Col>
                  <Col md={3} className="board-player-center">{player.score}</Col>
                </Row>
              </Col>
            )
          })
        }
      </Row>
      : <h3>Waiting for players...</h3>
  }

  getArmedHeader() {
    return (
      <CardHeader>
        <Row>
          <Col md={12}>
            <h1>Clickers</h1>
            <h4>Login code: {this.props.fbData.id}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="text-left">
              <Button size="sm" className="header-button" onClick={this.openClearAll}>Clear all</Button>
            </div>
          </Col>
          <Col md={6}>
            <div className={'status-header '+((this.props.fbData.enable) ? 'standby' : 'disabled')}>
              <h4>{(this.props.fbData.enable) ? "Buzz in when ready" : "WAIT!"}</h4>
            </div>
          </Col>
          <Col md={3} className="text-right header-button">
            <Button size="sm" onClick={this.toggleEnable}>{(this.props.fbData.enable) ? 'Disable' : 'Enable'}</Button>
            <Button size="sm" onClick={this.openSetup}>Setup</Button>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  getLockoutHeader() {
    return (
      <CardHeader>
        <Row>
          <Col md={12}>
            <h1>Clickers</h1>
            <h4>Login code: {this.props.fbData.id}</h4>
          </Col>
        </Row>
        <Row>
          {
            (this.state.firstPlayer) ?
              <Col md={{offset: 3, size: 6}} className="status-header buzzed-in">
                <h4>{this.state.firstPlayer.name} buzzed in</h4>
              </Col>
              :
              <Col md={{offset: 3, size: 6}} className="status-header buzzed-in">
                <h4>Player was removed</h4>
              </Col>
          }
          <Col md={3} className="align-right">
            <div className="header-button">
              <Button color="primary" size="sm" onClick={this.correct}>Correct</Button>
              <Button color="primary" size="sm" onClick={this.wrong}>Wrong</Button>
              <Button color="primary" size="sm" onClick={this.next}>Next</Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  getSound() {
    return (
      <Sound
        url={buzzerSound}
        playStatus={(this.state.playSound) ? Sound.status.PLAYING : Sound.status.STOPPED}
        playFromPosition={0}
        onFinishedPlaying={this.whenFinishedPlaying}
      />
    )
  }

  getSetupModal() {
    return (
      <Modal isOpen={this.state.setupModal} toggle={this.openSetup}>
        <ModalHeader toggle={this.openSetup}>Clicker setup</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label sm={8} for="correctPoints" className="text-right">Add points for a correct answer:</Label>
              <Col sm={4}>
                <Input id="correctPoints" type="number" value={this.props.correctPoints}
                       onChange={this.props.setPoints}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={8} for="wrongPoints" className="text-right">Deduct points for an incorrect answer:</Label>
              <Col sm={4}>
                <Input id="wrongPoints" type="number" value={this.props.wrongPoints} onChange={this.props.setPoints}/>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.openSetup}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }

  getClearAllModal() {
    return (
      <Modal isOpen={this.state.clearAllModal} toggle={this.openSetup}>
        <ModalHeader toggle={this.openClearAll}>Clear all players?</ModalHeader>
        <ModalBody>
          <h5>Are you sure you want to clear all the players? This cannot be undone.</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.clearAll}>Yes</Button>
          <Button color="primary" onClick={this.openClearAll}>No</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render() {
    // Extract players from the Firebase DB object; empty array if no players exist
    let players = (this.props.fbData) ? R.toPairs(this.props.fbData.playerList) : []
    players = R.map(player => player[1], players)

    // Generate the proper header depending on the app state
    let stateText = ''
    if (this.props.fbData.appState === STATES.ARMED) {
      // App is armed for the next person to buzz in
      stateText = this.getArmedHeader()

    } else if (this.props.fbData.appState === STATES.LOCKOUT) {
      // App is locked out for subsequent players to buzz in
      // Show buzzed in first with buttons to select if they're correct or not
      stateText = this.getLockoutHeader()
    }

    return (
      <Card>
        {stateText}
        <CardBody>
          {this.getGrid(players)}
          {this.getSound()}
        </CardBody>
        <Footer />
        {this.getSetupModal()}
        {this.getClearAllModal()}
      </Card>
    )
  }
}

export default Standby;
