import React, {Component} from 'react';
import Footer from '../shared/Footer'
import moment from 'moment'
import {Form, Row, Col, FormGroup, Label, Input, Button, Card, CardHeader, CardBody} from 'reactstrap';
import {SYSTEM} from '../shared/Constants'

class SetPlayer extends Component {

  constructor(props) {
    super(props)
    let lastIdObj = (localStorage) ? JSON.parse(localStorage.getItem('clickers-last-id')) : null
    if (!lastIdObj || (moment().unix() - lastIdObj.time > 300)) lastIdObj = null
    this.onChange = this.onChange.bind(this)
    this.loginClick = this.loginClick.bind(this)
    this.useLastId = this.useLastId.bind(this)
    this.state = {
      id: '',
      name: '',
      ...lastIdObj,
    }
  }

  loginClick() {
    this.props.fb.setPlayer(this.state.id, this.state.name, this.props.onFbChange)
    if (localStorage) {
      localStorage.setItem('clickers-last-id', JSON.stringify({
        lastId: this.state.id,
        lastName: this.state.name,
        time: moment().unix()
      }))
    }
  }

  useLastId() {
    this.props.fb.setPlayer(this.state.lastId, this.state.lastName, this.props.onFbChange)
  }

  onChange(evt) {
    let field = evt.currentTarget.getAttribute('id')
    let val = evt.currentTarget.value.toUpperCase()
    this.setState({[field]: val})
  }

  render() {
    return (
      <Row>
        <Col sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}}>
          <Card>
            <CardHeader>
              <h4>Player login</h4>
            </CardHeader>
            <CardBody>
              <Col sm={{size: 8, offset: 2}} md={{size: 6, offset: 3}}>
                <Form>
                  <FormGroup>
                    <Label for="id">Login code:</Label>
                    <Input id="id"
                           type="text"
                           onChange={this.onChange}
                           value={this.state.id}
                           className="text-center"
                           placeholder="Login code"/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">Name (12 letters max):</Label>
                    <Input id="name"
                           type="text"
                           maxLength={12}
                           onChange={this.onChange}
                           value={this.state.name}
                           className="text-center"
                           placeholder="Name"/>
                  </FormGroup>
                  <FormGroup>
                    <Button color="primary"
                            block
                            disabled={this.state.id.length !== SYSTEM.ID_LENGTH || this.state.name.length === 0}
                            onClick={this.loginClick}>Log
                      in</Button>
                  </FormGroup>
                  {
                    (this.state.lastId && this.state.lastName) ?
                      <div>
                        <Button block
                                color="primary"
                                onClick={this.useLastId}>Use last ID and name</Button>
                      </div> : null
                  }
                  {
                    (this.props.invalidCode) ?
                      <FormGroup>
                        <Label className="invalid-code">Invalid code, try again</Label>
                      </FormGroup> :
                      null
                  }
                </Form>
              </Col>
            </CardBody>
            <Footer />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default SetPlayer;
